<template>
  <transition name="fade">
    <div
      class="slideshow"
      v-if="loading === false"
    >
      <div
        v-if="noImages === false"
        class="swiper-container"
      >
        <div
          class="swiper-wrapper"
        >
          <SlideshowImage
            v-for="(file, index) in files"
            :key="index"
            :dataUrl="file.dataUrl"
          />
        </div>
      </div>
      <div
        v-else-if="noImages === true"
        class="slideshow__error"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewPort="0 0 24 24" width="24" height="24"><path d="M13 13h-2V7h2m0 10h-2v-2h2M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z"/></svg>
        <div>Keine Bilder gefunden</div>
      </div>
    </div>
    <Loading
      v-else
    />
  </transition>
</template>

<script>
import SlideshowImage from '@/components/SlideshowImage.vue';
import Loading from '@/components/Loading.vue';

// https://swiperjs.com
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

export default {
  name: 'Slideshow',
  components: {
    SlideshowImage,
    Loading,
  },
  data: () => ({
    files: [],
    swiper: null,
    loading: null,
    noImages: null,
  }),
  mounted() {
    this.loading = true;
    this.initData().then(() => {
      // console.log('%c initData:finished ', 'background-color: #bada55; color: black;');
      setTimeout(() => {
        this.initSwiper();
      }, 1000);
      this.loading = false;
    });
  },
  beforeDestroy() {
    this.swiper = null;
  },
  methods: {
    initData() {
      return this.$apiGetImages()
        .then((response) => {
          if (response.data.length > 0) {
            this.files = response.data;
            this.noImages = false;
          } else {
            this.noImages = true;
          }
        })
        .catch((error) => {
          this.noImages = true;
          /* eslint-disable */
          console.log('error', error);
          /* eslint-enable */
        });
    },
    initSwiper() {
      // eslint-disable-next-line
      this.swiper = new Swiper('.swiper-container', {
        // fade
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        // flip
        // effect: 'flip',
        speed: 3000,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        // on: {
        //   init: () => {
        //     console.log('swiper initialized');
        //   },
        // },
      });

      // this.swiper.on('slideChange', () => {
      //   console.log('slide changed');
      // });
    },
  },
};
</script>

<style lang="scss">
.slideshow {
  width: 100%;
  height: 100%;

  &__error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ccc;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;

    svg {
      transform: scale(5);
      transform-origin: bottom center;
      fill: #ccc;
    }

    div {
      margin-top: 50px;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}
</style>
