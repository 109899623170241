<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height d-flex flex-columns justify-center align-center pulse"
      >
        <v-progress-circular
          :size="500"
          :width="3"
          indeterminate
          color="blue"
        />
        <h1 class="loading blue--text">Loading</h1>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0px 0px 5px rgba(33,150,243,0.25);
}

@keyframes pulseAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.25; }
  100% { opacity:1; }
}
.pulse {
    animation: pulseAnimation 3s infinite;
}
</style>
