import axios from 'axios';

const apiUrl = API_URL;  // eslint-disable-line

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$apiGetImages = () => new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}images/`,
        method: 'get',
        headers: {
          Accept: 'application/json',
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$apiDeleteImage = (id) => new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}image/`,
        method: 'delete',
        data: {
          id,
        },
        headers: {
          Accept: 'application/json',
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$apiUploadImages = (files) => new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}upload/`,
        method: 'post',
        headers: {
          Accept: 'application/json',
        },
        data: files,
      }).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$apiSortImages = (files) => new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}sort/`,
        method: 'post',
        headers: {
          Accept: 'application/json',
        },
        data: files,
      }).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
