<template>
  <Slideshow />
</template>

<script>
import Slideshow from '@/components/Slideshow.vue';

export default {
  name: 'Home',
  components: {
    Slideshow,
  },
};
</script>
