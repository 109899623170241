<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  // data: () => ({
  // }),
};
</script>

<style>
  html {
    overflow: hidden !important;
  }

  html,
  body {
    position: relative;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #000000;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
