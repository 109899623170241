<template>
  <div
    class="swiper-slide"
    :style="`background-image: url(${dataUrl})`"
  />
</template>

<script>

export default {
  name: 'SlideshowImage',
  props: {
    dataUrl: {
      required: true,
      type: String,
      default: () => '',
    },
  },
};
</script>
